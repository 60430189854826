import CaptionedImage from '../CaptionedImage.js'

import { Trans, useTranslation } from 'react-i18next'
export default function DR(){
	const {t, i18n} = useTranslation("dr");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{ t('c1') }
			</p>
				<CaptionedImage src="/images/elma_dr.jpg" caption={t('cap1') }/>
			<p>
				{ t('c2') }
			</p>
				<CaptionedImage src="/images/admin.png" caption={t('cap2') }/>
			<p>
				{ t('c3') }
			</p>

			<CaptionedImage src="/images/elma_dr.jpg" caption={t('cap3') }/>
			<p>
				{ t('c4') }
			</p>
			<CaptionedImage src="/images/editor.png" caption={t('cap4') }/>
			<p>
				{ t('c5') }
			</p>
			<p>
				{ t('c5.5') }
			</p>
			<p>
				{ t('c6') }
			</p>
		</div>
	)

}


